import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"
import styled from 'styled-components'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import ButtonWithImageNew from '../../components/common/ButtonWithImageNew'
import MainContainer from "../../components/containers/MainContainer"
import HeadDataNew from "../../data/HeadDataNew"
import YoutubePlayerLite from "../../components/common/YoutubePlayerLite"

const StyledDiv = styled.div`
  transition: all .2s ease-in-out;

  &:hover {
    transform: scale(1.02);
  }
`

const Container = 'relative bg-white cursor-pointer flex flex-col p-4 rounded shadow-md hover:shadow-xl border border-gray-200 hover:border-red-800 text-red-900 hover:text-red-800 overflow-hidden'
const titleWrapper = 'bg-gray-100 p-2 mt-2 rounded'
const Title = 'font-display text-2xl uppercase text-center tracking-wider'
const productImg = 'w-64 mx-auto'

const CommandPackagesProductTemplate = ({ data }) => {
  const product = data.prismicProduct
  const imageData = getImage(product.data.product_image)
  const imgSrc = getSrc(product.data.product_image)
  const imageBadge = getImage(product.data.product_badge)

  const imageInst = getImage(data.imageInst)
  const imageProductPlaceholder = getImage(data.imageProductPlaceholder)
  const imageAccessories = getImage(data.imageAccessories)

  return (
    <MainContainer>
      <HeadDataNew
        product
        title={`${product.data.product_name && product.data.product_title ? product.data.product_title : product.data.product_name} | ${product.data.category}`}
        description={product.data.product_short_description && product.data.product_description ? product.data.product_description : product.data.product_short_description}
        image={imgSrc ? imgSrc : null}
      />

      <div className='bg-white pb-6'>
        <div className='py-2 md:py-6'>
          <div className='w-11/12 xl:max-w-screen-xl flex flex-row items-center justify-between mx-auto'>
            <div>
              <div className='text-gray-600 text-xs md:text-sm'><Link to='/' className='font-semibold hover:underline'>Home</Link> &raquo; <Link to="/command-packages" className='font-semibold hover:underline'>Command Packages</Link> &raquo; {product.data.product_name ? product.data.product_name : null}</div>
            </div>
          </div>
        </div>

        <div className='w-11/12 xl:w-8/12 xl:max-w-screen-xl flex flex-col items-center justify-center pt-6 mb-0 mx-auto'>
          <h1 className='font-display text-4xl md:text-5xl text-red-900 text-center tracking-wider leading-tight'>{product.data.product_name ? product.data.product_name : 'Product Name'}</h1>
          <p className='w-full md:w-8/12 text-lg md:text-xl text-gray-700 text-center'>{product.data.product_short_description ? product.data.product_short_description : 'Short Description of Product'}</p>
        </div>

        <div className='w-11/12 xl:w-9/12 xl:max-w-screen-lg flex flex-col md:flex-row mt-6 sm:mt-4 sm:mb-6 mx-auto pt-8 border-t border-red-200'>
          <div className='w-full md:w-5/12 mb-6 md:mb-0'>
            <GatsbyImage
              image={imageData ? imageData : imageProductPlaceholder}
              alt={product.data.product_name ? product.data.product_name : ''}
              className='w-full mx-auto rounded-lg shadow'
            />

            {product.data.youtube_video_id
              ?
              <div className='mt-6'>
                <p className='font-body text-base text-gray-600'>{product.data.product_long_description ? product.data.product_long_description.text : 'Product description here.'}</p>
              </div>
              :
              null
            }
          </div>

          <div className='w-full md:w-7/12 mx-auto'>
            <div className='md:pl-6'>
              {product.data.youtube_video_id
                ?
                <div className='w-full mb-6 shadow-md'>
                  <YoutubePlayerLite youtubeID={product.data.youtube_video_id} />
                </div>
                :
                <div className='mb-6'>
                  <p className='font-body text-base text-gray-600'>{product.data.product_long_description ? product.data.product_long_description.text : 'Product description here.'}</p>
                </div>
              }

              <div className='mr-0 md:mr-2'>
                {product.data.body
                  ?
                  product.data.body.map(slice => {
                    if (slice.slice_type === 'cutsheet_file') {
                      return (
                        slice.items.map(item => {
                          return (
                            <a href={item.cutsheet_file.url} target='_blank' rel='noopener noreferrer'>
                              <ButtonWithImageNew title={item.cutsheet_button_name ? item.cutsheet_button_name : 'Cutsheet'} productName={item.cutsheet_document_for} />
                            </a>
                          )
                        })
                      )
                    } else if (slice.slice_type === 'instructions_file') {
                      return (
                        slice.items.map(item => {
                          return (
                            <a href={item.instructions_file.url} target='_blank' rel='noopener noreferrer'>
                              <ButtonWithImageNew imageData={imageInst} title={item.instructions_button_name ? item.instructions_button_name : 'Instructions'} productName={item.instructions_document_for} />
                            </a>
                          )
                        })
                      )
                    } else {
                      return null
                    }
                  })
                  :
                  null
                }
              </div>

              <div className='mt-6'>
                <a href={product.data.product_badge_pdf_file.url ? product.data.product_badge_pdf_file.url : "https://commandaccess.cdn.prismic.io/commandaccess/1e8c4e8d-3e94-436e-b4bc-64d01c3cf4da_20465_A-Command_Access_Warranty.pdf"} target='_blank' rel='noopener noreferrer'>
                  <GatsbyImage
                    image={imageBadge ? imageBadge : null}
                    alt='Warranty icon and other specification images'
                    className='w-2/3 md:w-1/2'
                  />
                </a>
              </div>

              <div className='mt-6'>
                <Link to='/distributors'>
                  <button className="bg-white hover:bg-red-800 border border-red-800 font-display text-red-800 hover:text-white text-sm md:text-xl uppercase font-bold py-2 px-4 rounded tracking-widest shadow">
                    Find Distributors
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className='w-11/12 xl:w-9/12 xl:max-w-screen-lg mt-8 mb-0 mx-auto pt-6 border-t border-red-200'>
          <h2 className='text-2xl md:text-3xl text-gray-800 text-center md:text-left leading-none mb-3'>Recommended <span className='text-red-900'>Add-On Products</span></h2>
          
          <div className='bg-white py-4'>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
              <Link to='/accessories'>
                <StyledDiv className={Container}>
                  <GatsbyImage
                    image={imageAccessories}
                    alt='accessories'
                    className={productImg}
                  />
                  <div className={titleWrapper}>
                    <h2 className={Title}>Accessories</h2>
                  </div>
                </StyledDiv>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </MainContainer>
  )
}

export default withPrismicPreview(CommandPackagesProductTemplate)

export const CommandPackagesProductTemplateQuery = graphql`
  query($id: String!) {
    prismicProduct(id: { eq: $id }) {
      _previewable
      uid
      data {
        category
        featured_product
        motor_driven
        product_brand
        product_name
        product_title
        product_description
        product_short_description
        product_long_description {
          text
        }
        product_type
        youtube_video_id
        product_image {
          gatsbyImageData(width: 500)
        }
        product_badge {
          gatsbyImageData(width: 617)
        }
        product_badge_pdf_file {
          url
        }
        body {
          __typename
          ... on PrismicProductDataBodyCutsheetFile {
            id
            items {
              cutsheet_button_name
              cutsheet_document_for
              cutsheet_file {
                url
              }
            }
            slice_type
          }
          ... on PrismicProductDataBodyInstructionsFile {
            id
            items {
              instructions_button_name
              instructions_document_for
              instructions_file {
                url
              }
            }
            slice_type
          }
        }
      }
    }
    imageProductPlaceholder: file(relativePath: { eq: "logo/command-access-logo-icon.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 500
        )
      }
    }
    imageInst: file(relativePath: { eq: "pages/instructions-bg.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 600
        )
      }
    }
    imagePowerSupplies: file(relativePath: { eq: "products/power-supplies/PS210-Power-Supply.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 500
        )
      }
    }
    imagePowerTransfers: file(relativePath: { eq: "products/power-transfers/ETH-5-Knuckle.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 500
        )
      }
    }
    imageAccessories: file(relativePath: { eq: "products/accessories/Accessories.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 500
        )
      }
    }
    imageExitDeviceKits: file(relativePath: { eq: "products/exit-device-kits/MLRK1-YAL6-72506.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 500
        )
      }
    }
    imageMortiseLocks: file(relativePath: { eq: "products/mortise-locks/ML90-M-Series-Schlage-L9000.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 500
        )
      }
    }
  }
`